const emailTemplateBox1 = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <meta charset="UTF-8">
  <meta content="width=device-width, initial-scale=1" name="viewport">
  <meta name="x-apple-disable-message-reformatting">
  <meta name="format-detection" content="email=no">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta content="telephone=no" name="format-detection">
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Comfortaa&display=swap" rel="stylesheet">
</head>

<style>
  .personalized-link a {
    color: #107c41 !important;
    font-size: "14px" !important;
    text-decoration: none !important;
  }

  p {
    margin: 0 !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
  }
</style>

<body>
  <table cellpadding="0" cellspacing="0" align="center" style="width: 50%; height: fit-content; background-color: #F0F9F4; font-family: Arial, Helvetica, sans-serif; ">
    <thead style="height: 220px;">
      <td align="center" style="background-position: top; background-size: cover"
        background="https://i.imgur.com/Lns32D4.jpeg">
      </td>
    </thead>
    <tbody>
      <tr>
        {downloadContent}
      </tr>
      <tr align="left">
        <td class="content" style="padding: 20px 30px; font-size: 18px; margin: 0; -webkit-font-smoothing: antialiased;">
          {content}
        </td>
      </tr>
    </tbody>
    <tfoot align="center" style="height: fit-content;">
      <td style="padding-top: 30px">
        <div style="background:#107c4180; height: 1px; width: 90%; padding-left: 30px"></div>
        <p style="font-size: 12px; padding: 10px; pointer-events: none; text-decoration: none;" >
          Enviado por Agroconsult Rodovia SC 401, 4756 - 88032005 - Florianópolis, Santa Catarina, Brasil
        </p>
      </td>
    </tfoot>

  </table>

</body>

</html>`

export default emailTemplateBox1