import { useState } from 'react';
import { Box } from '@mui/material';

import CustomTemplate from './components/CustomTemplate';
import RadioLang from './components/RadioLang';
import TextEditor from './components/TextEditor';
import emailTemplateBox2 from '../../helpers/emailsTemplates/box2/emailTemplateBox2';
import removeDiacriticsAndSpecialChars from './utils/removeSpecialChars';
import { Menu, SelectField, InputField, ButtonSend, Upload, Switch } from '../../components';
import { MAILER_URL } from '../../config/constants';

const EMAIL_SENDER = process.env.REACT_APP_EMAIL_SENDER;
const NAME_SENDER = process.env.REACT_APP_NAME_SENDER;
const EMAIL_COPY = process.env.REACT_APP_EMAIL_COPY;
const NAME_COPY = process.env.REACT_APP_NAME_COPY;

const Mail = () => {

  const [files, setFiles] = useState(null);
  const [language, setLanguage] = useState('');
  const [boxEnabled, setBoxEnabled] = useState('Box 1');
  const [call, setCall] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [editorValueNoTags, setEditorValueNoTags] = useState('');
  const [sourceCode, setSourceCode] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const [formData, setFormData] = useState();
  const [dynamicContent, setDynamicContent] = useState('');
  const [filesNames, setFilesNames] = useState([]);

  const getFiles = async (data) => {
    
    setFiles(data);

    setFilesNames([]);

    data?.getAll('files').forEach((file) => {
      const fileName = file.name;
      const cleanText = removeDiacriticsAndSpecialChars(fileName);
      const newName = cleanText;
      setFilesNames(state => [...state, newName]);
    });

  };

  const getSourceCodeValue = (data) => setSourceCode(data);

  const getEditorData = async (valueEditor, valueEditorNoTags) => {
    setEditorValue(valueEditor);
    setEditorValueNoTags(valueEditorNoTags);
  };

  const getCustomContentValue = (data) => setDynamicContent(data);
  const getSelectedGroup = (data) => setSelectedGroup(data);
  const handleSwitchChange = (value) => setLanguage(value);
  const handleSwitchBoxChange = (value) => {
    const boxEnabled = value ? 'Box 1' : 'Box 2';
    setBoxEnabled(boxEnabled);
  };

  const verifyFields = (form) => {
    const data = new FormData(form);

    setFormData(data);
    setCall(!call);

    data.append('language', language);
    data.append('sourceCode', sourceCode ? true : false);
    data.append('boxEnabled', boxEnabled);
    data.append('groupId', selectedGroup.id);

    if (boxEnabled === 'Box 2') {

      const insertedDynamicContent = emailTemplateBox2
        .replace('{content}', dynamicContent['main'])
        .replace('{coverImg}', dynamicContent['coverImg'])
        .replace('{footer}', dynamicContent['footer']);

      data.append('content', insertedDynamicContent);
    } else data.append('content', sourceCode ? editorValueNoTags : editorValue);

    for (const [key, value] of data.entries()) {

      if ((value === '' && key !== 'copyName' && key !== 'copyEmail') || value === '<p><br></p>') return;
    };

    return true;
  };

  const handleSubmit = async () => {

    files?.getAll('files').forEach((file) => {

      const fileName = file.name;
      const cleanText = removeDiacriticsAndSpecialChars(fileName);

      const newName = cleanText;
      formData.append('files', new File([file], newName, { type: file.type }));

    });

    const options = {
      method: 'POST',
      body: formData,
      credentials: 'include'
    };

    const response = await fetch(`${MAILER_URL}/email`, options);

    const responseBody = await response.json();
    const { error } = responseBody;

    if (response.status === 200) return;
    else return error;

  };

  return (
    <Box style={styles.container}>

      <Menu tabState='emails' indexSelected='' />

      <Box
        component='form'
        style={styles.contentBox}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >

        <Box style={styles.columnMailBody}>

          <Box style={styles.mailFilterBox}>

            <Box style={{ width: '70%' }}>
              <SelectField
                getSelectedValue={getSelectedGroup}
                nameVariable='send_group'
                URL={`${MAILER_URL}/group/getAll`}
                isEmpty={false}
                label='Grupo de Envio'
              />

            </Box>

            <RadioLang onChange={handleSwitchChange} call={call} />
          </Box>

          <Box>

            <Box style={styles.divisor}>

              <InputField
                label={'Nome do Remetente'}
                width={'38%'}
                name={'senderName'}
                defaultValue={NAME_SENDER}
                call={call}
                type={'name'}
              />

              <InputField
                label={'E-mail do Remetente'}
                width={'60%'}
                name={'senderEmail'}
                defaultValue={EMAIL_SENDER}
                call={call}
                type={'email'}
              />

            </Box>

            <Box style={styles.divisor}>

              <InputField
                label={'Nome da Cópia'}
                width={'38%'}
                name={'copyName'}
                call={call}
                defaultValue={NAME_COPY}
                type='name'
                optional
              />

              <InputField
                label={'CC (E-mail em Cópia)'}
                name={'copyEmail'}
                width={'60%'}
                call={call}
                defaultValue={EMAIL_COPY}
                type={'email'}
                optional
              />

            </Box>

            <Box style={styles.divisor}>
              <InputField
                label={'Assunto'}
                name={'subject'}
                call={call}
              />

              <Switch
                handleAction={handleSwitchBoxChange}
                options={['Box 1', 'Box 2']}
                width={'6em'}
                defaultChecked
              />

            </Box>

          </Box>

          {boxEnabled === 'Box 1' && (
            <TextEditor
              call={call}
              filesNames={filesNames}
              name='content'
              getEditorData={getEditorData}
              getSourceCodeValue={getSourceCodeValue}
              language={language}
            />
          )}

          {boxEnabled === 'Box 2' && (
            <CustomTemplate getCustomContentValue={getCustomContentValue} filesNames={filesNames} />
          )}

        </Box>

        <Box style={styles.columnFileSender}>

          <Box style={styles.boxAttachFile}>
            <Upload getFiles={getFiles} call={call} />

            <ButtonSend
              verifyFields={verifyFields}
              handleSubmit={handleSubmit}
              text={'Enviar E-mail'}
              description={'Deseja enviar este email para o grupo selecionado?'}
              descriptionSuccess={'Emails enviados com sucesso!'}
            />
          </Box>

        </Box>

      </Box>
    </Box>
  )
}

const styles = {

  container: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
  },

  contentBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 'fit-content',
    width: '100vw',
    gap: '2em',
    marginTop: '3%',
    padding: '30px',
  },

  columnMailBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '55%',
    height: '100%',
  },

  mailFilterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    marginBottom: '3%',
  },

  divisor: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
    boxSizing: 'border-box',
  },

  columnFileSender: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '35%',
    height: '100%',
  },

  boxAttachFile: {
    position: 'sticky',
    top: '150px',
    display: 'flex',
    flexDirection: 'column',
    height: '70vh',
    alignItems: 'center',
    justifyContent: 'space-between'
  }

}

export default Mail;