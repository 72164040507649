import { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';
import CustomToolbar from './CustomToolbar';

const convertRgbToHex = (rgb) => {
  const matches = rgb.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
  if (!matches) return;
  const r = parseInt(matches[1]).toString(16).padStart(2, '0');
  const g = parseInt(matches[2]).toString(16).padStart(2, '0');
  const b = parseInt(matches[3]).toString(16).padStart(2, '0');
  const hex = `#${r}${g}${b}`.toUpperCase();

  return hex;
};

const handleCheck = () => {return} 

const TextEditor = ({ call, getEditorData, getSourceCodeValue, filesNames, language }) => {
  const [editorValue, setEditorValue] = useState('');
  const [editorValueNoTags, setEditorValueNoTags] = useState('');
  const quillRef = useRef();

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const errorColor = theme.palette.error.main;

  const modules = {
    toolbar: {
      container: '#toolbar',
      handlers: {
        insertCodeOption: handleCheck
      }
    },
  }

  useEffect(() => {
    getEditorData(editorValue, editorValueNoTags);
  }, [editorValue, getEditorData, errorColor, editorValueNoTags]);

  useEffect(() => {
    
    const quill = quillRef.current.getEditor();
    const toolbar = quill.getModule('toolbar').container;
    const container = quill.container;

    if (call && (editorValue === '' || editorValue === '<p><br></p>')) {
      container.style.outline = `1px solid ${errorColor}`;
      toolbar.style.outline = `1px solid ${errorColor}`;
      toolbar.style.border = 'transparent';
      container.style.border = 'transparent';
    }

  }, [call, editorValue, errorColor]);

  const handleFocusEditor = () => {

    const quill = quillRef.current.getEditor();
    const toolbar = quill.getModule('toolbar').container;
    const container = quill.container;

    const outlineColor = convertRgbToHex(container.style.outlineColor);

    if ((editorValue === '<p><br></p>' || editorValue === '') && outlineColor === errorColor) {

      container.style.outline = `2px solid ${errorColor}`;
      toolbar.style.outline = `2px solid ${errorColor}`;
      return;

    };

    container.style.outline = `2px solid ${primaryColor}`;
    toolbar.style.outline = `2px solid ${primaryColor}`;

    container.style.borderColor = 'transparent';
    toolbar.style.borderColor = 'transparent';

  }

  const handleBlurEditor = () => {
    const quill = quillRef.current.getEditor();
    const toolbar = quill.getModule('toolbar').container;
    const container = quill.container;

    const outlineColor = convertRgbToHex(container.style.outlineColor);

    if (outlineColor !== primaryColor) {
      container.style.outline = `1px solid ${errorColor}`;
      toolbar.style.outline = `1px solid ${errorColor}`;
      return;
    }

    container.style.outline = `1px solid ${primaryColor}`;
    toolbar.style.outline = `1px solid ${primaryColor}`;

  };

  const handleEditor = (value) => {

    const quill = quillRef.current.getEditor();
    const toolbar = quill.getModule('toolbar').container;
    const container = quill.container;

    if (value === '<p><br></p>') {

      toolbar.style.outlineColor = `${errorColor}`;
      container.style.outlineColor = `${errorColor}`;

    } else {

      toolbar.style.outlineColor = `${primaryColor}`;
      container.style.outlineColor = `${primaryColor}`;

    };

    setEditorValueNoTags(quill.getText());
    setEditorValue(value);
  };
  
  return (
    <>
      <CustomToolbar getSourceCodeValue={getSourceCodeValue} dynamicContent={editorValue} filesNames={filesNames} language={language} />
      <ReactQuill
        onBlur={handleBlurEditor} 
        sizeStyle='true' 
        value={editorValue} 
        placeholder='Escreva sua mensagem aqui...' 
        onFocus={handleFocusEditor} 
        ref={quillRef} 
        modules={modules} 
        theme='snow' 
        onChange={(e) => handleEditor(e)} style={{ height: '100%' }} 
      />
    </>
  )
}

export default TextEditor;