const EmailTemplate = `
<!DOCTYPE html>
<html>

<head>
    <meta charset="UTF-8">
    <title>Email Template Box 2</title>
    <style>
        body {
            font-family: Verdana, Arial, sans-serif;
            margin: 0;
            padding: 0;
        }

        .container {
            text-align: center;
            width: 600;
            max-width: 600;
            background-color: #fff;
            border-bottom: 1px solid  #808080;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        h2 {
            color: #333;
            margin-top: 0;
            margin: 20px;
        }

        p {
            color: #777;
            line-height: 1.5;
            margin: 18px;
        }

        /* HEADER */
        .content-header {
            text-align: center;
            width: 600;
            max-width: 600;
        }

        .content-header img {
            width: 100;
            height: 100;
            margin: 0 auto;
            display: block;
        }

        .content-header h5 {
            text-align: center;
        }

        /* BODY */

        .dynamic-content {
            text-align: center;
        }

        .container-pdfs h4 {
            text-align: center;
        }

        .container-pdfs a {
            text-align: center;
            display: block;
            margin-bottom: 10px;
        }

        .dynamic-content p {
            text-align: justify;
            font-size: 13px;
        }

        .dynamic-content .gif {
            width: 100%;
        }

        .dynamic-content h2 {
            text-align: justify;
            color: #020020;
            font-size: 16px;
            padding-bottom: 10px;
            border-bottom: 1px solid #808080;
        }

        .dynamic-content img {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            display: block;
        }

        .dynamic-content .img-footer {
            width: 40%;
            height: 40%;
            margin: 0 auto;
            display: block;
            text-align: center;
        }

        .spacing-element {
            border-bottom: 1px solid  #808080;
            height: 5px;
            width: 100%;
            background-color: #f4f4f4;
        }

        /* FOOTER */

        .footer-box {

            margin-top: 5px;
            background-color: #f4f4f4!important;
            width: 600px;
            max-width: 600px;
            background-color: #fff;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        .footer-box p {
            text-align: center;
            font-size: 12px;
            margin-top: 10px;
        }

        .footer-box .fixed-text {
            margin-bottom: 15px;
        }

        .footer-box img {
            background-color: transparent;
        }

        .border-element {
            border-bottom: 1px solid  #808080;
            width: 80%;
            margin: 10px auto;
            text-align: center;
        }

    </style>
</head>

<body>
    <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="100" bgcolor="#f4f4f4">
        <tr>
            <td style="padding: 20px;">
                <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="100">
                    <tr>
                        <td>

                            <table class="container" role="presentation" cellspacing="0" cellpadding="0" border="0"
                                align="center" width="600">
                                <tr>
                                    <td class="content-header">
                                        {coverImg}
                                    </td>
                                </tr>
                            </table>

                            <table class="container" role="presentation" cellspacing="0" cellpadding="0" border="0"
                                align="center" width="600">
                                <tr>
                                    <td class="dynamic-content">
                                        {content}
                                    </td>
                                </tr>
                            </table>

                            {downloadContent}

                            <table class="footer-box" role="presentation" cellspacing="0" cellpadding="0"
                                border="0" align="center" width="600">
                                <tr>
                                    <td class="dynamic-content">
                                        {footer}
                                        <p class="fixed-text">Enviado por Agroconsult Rodovia SC 401, 4756 - 88032005 - Florianópolis, Santa Catarina, Brasil
                                            <br>
                                            Rodovia SC 401, 4756 - 88032005 - Florianópolis, Santa Catarina, Brasil
                                        </p>
                                    </td>
                                </tr>
                            </table>

                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>

</html>
`;

export default EmailTemplate;