import { Box } from '@mui/material';
import { Quill } from 'react-quill';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckboxCode from './CheckboxCode';
import emailTemplateBox1 from '../../../../../helpers/emailsTemplates/box1/emailTemplateBox1';
import downloadBox1 from '../../../../../helpers/emailsTemplates/box1/downloadBox1';

const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['15px', '18px', '20px', '24px', '28px'];
Quill.register(SizeStyle, true);

const CustomToolbar = ({ getSourceCodeValue, dynamicContent, filesNames, language }) => {

  const handleOpenHTMLPage = () => {
    const filesNamesToString = filesNames.map(fileName => `<a href="#">${fileName}</a> <br /><br />`).join(' ');
    let greeting = ``;

    if (language === 'portugues' || language === ''){
        greeting =
            `<tr><td class="content" style="padding: 30px 30px 20px 30px; font-size: 18px; margin: 0; -webkit-font-smoothing: antialiased;">
                <p>Prezados, segue anexo o link do relatório:</p>
            </td></tr>`
        } else if (language === 'ingles'){
            greeting =
                `<td class="content" style="padding: 30px 30px 20px 30px; font-size: 18px; margin: 0; -webkit-font-smoothing: antialiased;">
                    <p>Dear all, follow below the link for the report:</p>
                </td>`
        }

    const newTab = window.open('', '_blank');
    const insertedDynamicContent = emailTemplateBox1.replace('{content}', dynamicContent)
      .replace('{downloadContent}', filesNamesToString ? downloadBox1 : '')
      .replace('{links}', filesNamesToString)
      .replace('{greeting}', greeting)
    newTab.document.write(insertedDynamicContent);
    newTab.document.close();
  };

  return (
    <div id='toolbar' style={{ display: 'flex' }}>
      <span className='ql-formats'>
        <select className='ql-size' defaultValue='18px'>
          <option value='15px'>Pequeno</option>
          <option value='18px'>Normal</option>
          <option value='24px'>Grande</option>
        </select>
      </span>
      <span className='ql-formats'>
        <button className='ql-bold' />
        <button className='ql-italic' />
        <button className='ql-underline' />
        <button className='ql-strike' />
      </span>
      <span className='ql-formats'>
        <button className='ql-list' value='ordered' />
        <button className='ql-list' value='bullet' />
      </span>
      <span className='ql-formats'>
        <button className='ql-script' value='super' />
        <button className='ql-script' value='sub' />
      </span>
      <span className='ql-formats'>
        <select className='ql-align' />
        <select className='ql-color' />
      </span>
      <span className='ql-formats'>
        <button className='ql-link' />
      </span>
      <Box style={{ display: 'flex', width: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
        <span className='ql-formats'>
          <button style={styles.buttonCheckboxCode} className='ql-insertCodeOption'>
            <CheckboxCode getSourceCodeValue={getSourceCodeValue} />
          </button>
        </span>
        <span className='ql-formats' style={{ marginBlockEnd: 'end', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <button style={styles.buttonCheckboxCode} type='button' className='ql-insertCodeOption' onClick={() => handleOpenHTMLPage()}>
            Preview
          </button>
          <RemoveRedEyeIcon color='primary' />
        </span>
      </Box>
    </div>
  )
};

const styles = {
  buttonCheckboxCode: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
  }
}


export default CustomToolbar;