const downloadBox1 = `
      {greeting}
      <tr align="center" style="padding: 0 50px; width: 200px;">
        <td align="center" style="padding: 0 200px; text-align: center; vertical-align: middle;">
          <div style="border: 1px solid #107c41; border-radius: 5px;">
            <div style="display: flex;text-align:center;vertical-align:middle;background:#107c41;color:white"> 
              <div style="margin: 5px auto; display: flex; align-items: center; text-align:center;vertical-align:middle">
                <p style="font-size:14px;vertical-align:middle">DOWNLOAD</p>
                <img src="https://i.imgur.com/OVpa7sU.png" style="margin: auto 0; height:20px;padding-left:2px;vertical-align:middle" class="CToWUd" data-bit="iit">
              </div>
            </div>
            <div style="padding: 15px 10px;">
              <p class="personalized-link">{links}</p>
            </div>
          </div>
        </td>
      </tr>

`

export default downloadBox1;